import React, { useContext } from 'react';    
import { Link } from "react-router-dom";
import logo from '../img/help.webp';
import { PageContext } from '../hooks/PageContext';


function LinkToMain()
{    
    const pageContext = useContext(PageContext);
    var newContext = {
        Lang: pageContext.Lang,
        QuestionNumber: 0,
        FuncToUpdate: pageContext.FuncToUpdate
    };       
    var funcToUpdate = () => pageContext.FuncToUpdate(newContext);


    return <div id='helpBar'>
        <Link to="/" onClick={funcToUpdate}>
            <img src={logo} alt="To Main Page"/>
        </Link>            
    </div>
}

export default LinkToMain;

/*
import '../css/pageStructure.css';
import QuestionPageAddress from './QuestionPageAddress';
import { Link } from "react-router-dom";
import { PageContext } from '../hooks/PageContext';

function LinkToQuestion (props)
{   
    const pageContext= useContext(PageContext);

    var newContext = {
        Lang: props.Language,
        QuestionNumber: props.Number,
        FuncToUpdate: pageContext.FuncToUpdate
    };       
    var funcToUpdate = () => pageContext.FuncToUpdate(newContext);      

    var link = QuestionPageAddress(props.Language, props.Number);
    return <Link to={link} onClick={funcToUpdate}> {props.Number} </Link>;    
}

export default LinkToQuestion;
*/ 