import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer15 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <ul>
                <li> Все световые приборы следует проверять визуально при работающем двигателе автомобиля.</li>
                <li> Подключите фары и осмотритесь.</li>
                <li> В передней части автомобиля должен быть включен ближний или дальний свет (в зависимости от ситуации), ОДХ должны быть включены</li>
                <li> В задней части автомобиля могут быть включены красный дальний свет, фонарь заднего хода, задние противотуманные фары, если они установлены, и фонарь освещения номерного знака. </li>                    
            </ul>	
        </div>
    }
}

export default Answer15;