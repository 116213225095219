import React, { useContext } from 'react';    
import { Link } from "react-router-dom";
import langRu from '../img/LangRu.png';
import langLt from '../img/LangLt.png';
import { LT, RU } from '../data/Constants';
import { PageContext } from '../hooks/PageContext';


function LanguageSwitch(){
    // Get current page context
    const pageContext= useContext(PageContext);

    // Get new url
    var currentUrl = window.location.href;
    var newUrl = "/";        
    var newLang = RU;

    const ruUrl = 'Lang='+RU;
    const ltUrl = 'Lang='+LT;
    if(currentUrl.includes(ruUrl)){
        newUrl = currentUrl.replace(ruUrl, ltUrl);            
        newLang = LT;
    }
    else if(currentUrl.includes(ltUrl)){
        newUrl = currentUrl.replace(ltUrl, ruUrl);            
        newLang = RU;
    }

    // Prepare next context
    var newContext = {
        Lang: newLang,
        QuestionNumber: pageContext.QuestionNumber,
        FuncToUpdate: pageContext.FuncToUpdate
    };       
    var funcToUpdate = () => pageContext.FuncToUpdate(newContext);      

    return <div id='langSelector' className="langChosenRu">
        <Link to={newUrl} onClick={funcToUpdate}>
            <img src={currentUrl.includes(ruUrl) ?  langLt : langRu} alt="To Main Page"/>
        </Link>            
    </div>
    
}

export default LanguageSwitch;