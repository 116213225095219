import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image04 from '../../img/04_AntiFreeze.png';

class Answer04 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <img src={image04} alt="Antifreeze cap"/>
            
            <ul>
                <li>Variklio aušinimo skysčio lygis tikrinamas po variklio dangčiu.</li>
                <li>Ten rasite specialų plastikinį rezervuarą, paprastai su mėlynu dangteliu ir garų simboliu viršuje.</li>
            </ul>
            
        </div>
    }
}

export default Answer04;