import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer13 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <iframe width="300" height="150" 
                src="https://www.youtube.com/embed/5HZ0HmJGUaw" 
                title="Проверяем стоп-сигналы 1." 						  
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                allowfullscreen/>

            <iframe width="300" height="150" 
                src="https://www.youtube.com/embed/AUaLsLcs2As" 
                title="Проверяем стоп-сигналы 2." 						  
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                allowfullscreen/>

            <ul>
                <li>Стоп-сигнал проверяется визуально при нажатии на педаль тормоза.</li>
                <li>Прижмитесь задней частью автомобиля к стене и посмотрите на отраженный красный стоп-сигнал или попросите кого-нибудь помочь вам</li>                        
            </ul>	
        </div>
    }
}

export default Answer13;