import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer09 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           Norėdami įjungti garsinį signalą, paspauskite garsinio signalo simbolį ant vairo.
        </div>
    }
}

export default Answer09;