import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image1 from '../../img/OpenTheHood.png';


class Answer01 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <img src={image1} alt="Where to open the hood"/>
                 
            <ul>
                <li>Alyvos lygis variklyje tikrinamas po automobilio variklio dangčiu.</li>
                <li>Norėdami atidaryti variklio dangtį, patraukite rankenėlę, esančią po prietaisų skydeliu kairėje pusėje.</li>
            </ul>	
        </div>
    }
}

export default Answer01;