import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import EngineImg from '../../img/OilEngine.jpg';

class Answer02 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <img src={EngineImg} alt="Engine"/>          

            <ul>
                <li>Įsitikinkite, kad mašina yra horizontalioje padėtyje. </li>
                <li>Pakelkite variklio dangtį.</li>
                <li>Jei tikrinate alyvos lygį variklyje, išjunkite variklį ir palaukite kelias minutes (bent 5 minutes, jei tai benzininis variklis), kad alyva ištekėtų į karterį. </li>
                <li>Kai variklis šaltas, alyvos lygį galite patikrinti iš karto. Ištraukite alyvos lygio matuoklį, nuvalykite jį švaria šluoste ir įdėkite atgal.  </li>
                <li>Ištraukite alyvos lygio matuoklį ir patikrinkite alyvos lygį. Lygis turi būti tarp Max ir Min žymų.</li>                    
            </ul>	
        </div>
    }
}

export default Answer02;