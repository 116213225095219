import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image17 from '../../img/17_wheelsPressure.png';


class Answer17 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <img src={image17} alt="How does recommended wheel pressure label look like"/>
            
                 <ul>
                     <li> Atsukite padangos vožtuvo dangtelį.  </li>
                     <li> Įdėkite manometrą. </li>
                     <li> Paspauskite jį ir kelias sekundes atleiskite.</li>
                     <li> Slėgio matuoklio skydelis rodys padangos slėgį.</li>                        
                 </ul>	
        </div>
    }
}

export default Answer17;