import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer03 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           В исправном авто рулевое колесо поворачивается с трудом, когда автомобиль не заведен, но легко поворачивается, когда автомобиль заведен. 
            <br/>
            Если рулевое колесо поворачивается с одинаковым усилием при работающем и неработающем двигателе, то либо гидроусилитель руля не работает, либо он неисправен.
        </div>
    }
}

export default Answer03;