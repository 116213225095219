import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image4 from '../../img/04_AntiFreeze.png';


class Answer05 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <img src={image4} alt="Antifreeze cap"/>
           
            <ul>
                <li> Aušinimo skysčio lygį reikia tikrinti tik tada, kai variklis yra šaltas. </li>
                <li> Mašina turi būti pastatyta ant lygaus paviršiaus, ne ant nuokalnės. </li>
                <li> Aušinimo skysčio lygis turi būti tarp MIN ir MAX žymų (kartais Full/Low), esančių aušinimo skysčio rezervuaro šone. </li>							
                <li> Jei aušinimo skysčio lygis žemas, įpilkite pakankamai tinkamo aušinimo skysčio. Pripilkite iki MAX žymos, bet neperpildykite.</li>							
            </ul>
        </div>
    }
}

export default Answer05;