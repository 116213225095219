import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image16 from '../../img/16_WheelsPressure.png';
import image16_2 from '../../img/16_WheelsPressure_2.png';
import image16_3 from '../../img/16_WheelsPressure_2.png';



class Answer16 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <img src={image16} alt="Where to find recommended wheels pressure 1"/>
            <img src={image16_2} alt="Where to find recommended wheels pressure 2"/>
            <img src={image16_3} alt="Where to find recommended wheels pressure 3"/>

            Вы можете найти рекомендуемое давление в шинах: , а на некоторых моделях автомобилей эта информация может быть найдена  или 
            
            <ul>
                <li> открыв крышку топливного бака </li>
                <li> на стойке со стороны водителя</li>
                <li> на крышке бардачка </li>
                <li> на крышке капота</li>                    
            </ul>	
        </div>
    }
}

export default Answer16;