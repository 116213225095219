import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image4 from '../../img/04_AntiFreeze.png';


class Answer05 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <img src={image4} alt="Antifreeze cap"/>
                 
            <iframe width="300" height="150" 
                src="https://www.youtube.com/embed/-pqQOgNpnI8" 
                title="Проверка уровня охлаждающей жидкости" 						  
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                allowfullscreen/>

            <ul>
                <li> Уровень охлаждающей жидкости проверяется только когда двигатель холодный.</li>
                <li> Машина должна стоять на ровной поверхности, не на наклоне</li>
                <li> Уровень охлаждающей жидкости должен находиться между метками MIN и MAX (иногда Full/Low) на боковой стенке бачка охлаждающей жидкости.</li>							
                <li> Если уровень охлаждающей жидкости низкий, добавьте достаточное количество подходящей охлаждающей жидкости. Долейте до отметки MAX, но не переливайте.</li>							
            </ul>
        </div>
    }
}

export default Answer05;