import '../css/pageStructure.css';
import React from 'react'; 

class AdBlock extends React.Component{
        render () 
        {       
                var currentUrl = window.location.origin;
                var adBlockUrl = currentUrl + '/adBlock.html';

                var iFrameId = 'adFrame';
                        
                return <div id='rightAdBlock'>
                        <iframe id={iFrameId} src={adBlockUrl}
                                title="adBlock"
                                height="620"
                                width="180"/>
                        </div> 
        }
}

export default AdBlock;