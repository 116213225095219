import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer19 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
             
            <ul>
                <li>Visi transporto priemonės ratai turi būti subalansuoti simetriškai. </li>
                <li>Patikrinkite, ar protektorius nėra nelygumų, pažeidimų, įtrūkimų, skylių ar išbrinkimų. Jei ką nors aptiksite, padangą reikia pakeisti. </li>
                <li>Jei pastebėjote audinį ar kordą, būtinai pakeiskite padangą nauja. </li>
                <li>Ant tos pačios ašies turi būti to paties aukščio, pločio, nusidėvėjimo ir sezono padangos.</li>                        
            </ul>		
        </div>
    }
}

export default Answer19;