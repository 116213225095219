import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer14 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <ul>
                <li> Рабочий (основной) тормоз считается технически исправным, если торможение эффективное при нажатии на педаль с 1\2 силы. </li>
                <li> Если при каждом нажатии на педаль тормоза она нажимается до упора, значит, тормоз неисправен и на автомобиле дальше ехать нельзя. </li>                        
            </ul>	
        </div>
    }
}

export default Answer14;