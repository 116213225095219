import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer12 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <ul>
                <li> Kad posūkių signalai veiktų, uždegimo jungiklis turi būti įjungtas.</li>
                <li> Norėdami įjungti posūkių signalus, pastumkite svirtelę aukštyn arba žemyn. </li>
                <li> Žalios rodyklės prietaisų skydelyje rodo, kuris posūkio signalas veikia. </li>
                <li> Jos išsijungs pačios, kai atlikę manevrą pasuksite vairą atgal.</li>
                <li> Jei indikatorius ir toliau mirksi, rankiniu būdu grąžinkite svirtelę į pradinę padėtį.</li>                    
                <li> Jei indikatoriaus lemputė mirksi labai greitai arba lėtai, gali būti, kad perdegė lemputė arba grandinėje yra blogas kontaktas.</li>                    
            </ul>	
        </div>
    }
}

export default Answer12;