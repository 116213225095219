import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import EngineImg from '../../img/OilEngine.jpg';

class Answer02 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <img src={EngineImg} alt="Engine"/>
                 
            <iframe width="300" height="150" 
                src="https://www.youtube.com/embed/NNnqvE36C1w" 
                title="Измеряем уровень масла - 1." 						  
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                allowfullscreen/>                

            <ul>
                <li>Убедитесь что машина находится в горизонтальном положении.</li>
                <li>Поднимите крышку капота</li>
                <li>Eсли вы проверяете уровен масла в двигателе, выключите двигатель и подождите пару минут (в бензиновом двигателе - хотя бы 5 минут), чтобы масло сбежало в картер. Когда двигатель холодный, уровень масла можете проверять сразу.</li>
                <li>Вытащите измеритель уровня масла, начисто очистите его тряпкой и засуньте обратно</li>
                <li>Вытащите измеритель масла и проверьте уровень масла. Уровень должен быть между отметками Макс и Мин</li>                    
            </ul>	
        </div>
    }
}

export default Answer02;