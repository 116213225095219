import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image7 from '../../img/07_Water.jpg';


class Answer08 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <img src={image7} alt="Window washer reservoir"/>
                 
            <iframe width="300" height="150" 
                src="https://www.youtube.com/embed/fFkt6YwnAjs" 
                title="Уровень жидкости омывателя - 1" 						  
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                allowfullscreen/>

            <iframe width="300" height="150" 
                src="https://www.youtube.com/embed/FyCt_NG1h6k" 
                title="Уровень жидкости омывателя - 2" 						  
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                allowfullscreen/>

            <iframe width="300" height="150" 
                src="https://www.youtube.com/embed/o0aO9PV3ZaI"
                title="Уровень жидкости омывателя - 3" 						  
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                allowfullscreen/>


            <ul>
                <li>Убедитесь, что машина находится в горизонтальном положении.</li>
                <li>Поднимите крышку капота. </li>
                <li>Бачок обычно прозрачный, с отметками MIN и MAX, поэтому уровень жидкости проверяется визуально.</li>
                <li>Световой индикатор на приборной панели также может указывать на то, что жидкость закончилась. </li>                        
            </ul>	
        </div>
    }
}

export default Answer08;