import React from 'react';   
import NavigationPanel from './NavigationPanel';
import LinkToMain from '../components/NavigationToMain';
import LanguageSwitch from '../components/LanguageSwitch';


function Header ()
{        
    return <div id='header'>
        <NavigationPanel/>            
        <LanguageSwitch />
        <LinkToMain/>
    </div>
}

export default Header;