import React, { useContext } from 'react';    
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import { PageContext } from '../hooks/PageContext';


function Main()
{             
    const pageContext = useContext(PageContext);
    var lang = pageContext.Lang;
    
    var helmet = <Helmet htmlAttributes={{"lang":"ru"}}
    title= 'Практические вопросы экзамена в Регитре'
    
    meta={[
        {"name":"description", "content": "Экзамен на вождение в Регитре - практические вопросы"},            
    ]}
    />


    var question = <div id='question'>
        <h1>Что это за сайт</h1> 
    </div>

    var answer =
    <div id='answer'>

        <div id='leftBlock'>
        <div>
            Этот сайт объединяет материалы для подготовки к практическим вопросам на экзамене на вождение в Литве. Для каждого из 20 вопросов мы постарались собрать:
            <ul>
                <li> Текст ответа. </li>
                <li> Немного фотографий. </li>
                <li> Видеогайды по темам вопросов. </li>						
            </ul>
            Читаем ответы, смотрим видео -- и половина подготовки пройдена. Осталось лишь проверить свои знания у инструктора в автошколе.
        </div>
        <br/><br/>

        <div>
            <b>Зачем нужен сайт, если инструктор в автошколе всё равно всё покажет?</b>
            
            Сайт позволит вести домашнюю подготовку. Зачем тратить время занятия (оплачиваемое время!) на то, к чему можно спокойно подготовиться дома? 
            
            Несомненно, после самостоятельной подготовки стоит проверить свои знания у инструктора  -- он поправит ошибки и укажет на пропуски. Но подготовку можно вести самостоятельно.
        </div>
        <br/><br/>

        <div> 
            <b> Почему бы мне не подготовиться самому?</b>
            <br/>
            Это возможно. Однако, у нас собраны материалы, проверенные временем и опытом. Кроме того, мы сэкономим вам время на поиск нужной информации.
        </div>
        <br/><br/>

        <div>
            <b> Можно ли здесь готовиться к теоретическим заданиям?</b>
            <br/>
            Пока нет. Возможно, мы выпустим материалы для самостоятельно подготовки чуть позже.
        </div>
        <br/><br/>
        
        <div>
            <b> Откуда берутся практические вопросы к экзамену?</b>
            <br/>
            С портала <a href='https://www.regitra.lt/lt/paslaugos/egzaminai/praktiniai-patarimai-ruosiantis-egzaminams/patikrinimo-pries-vaziavima-klausimai'> Регитры </a>					
        </div>
    </div>
    </div>

    return <div>
        {/* {<LocationLogger/>} */}
        {helmet}
        <Header Language={lang}/>
        {question}
        {answer}
        
        <Footer />
    </div>
}


export default Main;