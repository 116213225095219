function QuestionRu(number){
    switch(number){
        case 1: return "покажите где будете проверять уровень масла в двигателе.";
        case 2: return "объясните как вы будете проверять достаточный ли уровень масла в двигателе.";
        case 3: return "покажите и поясните как проверять что работает усилитель руля.";
        case 4: return "покажите где будете проверять уровень охлаждающей жидкости в системе.";
        case 5: return "объясните, как будете проверять достаточен ли уровень охлаждающей жидкости";
        case 6: return "объясните как будете проверять работают ли тормоза.";
        case 7: return "покажите, где находится резервуар жидкости для мытья окон.";
        case 8: return "объясните где будете проверять уровень жидкости для мытья окон.";
        case 9: return "покажите как проверите, работает ли звуковой сигнал автомобиля.";
        case 10: return "покажите где находится резервуар тормозной жидкости.";
        case 11: return "объясните, где будете проверять, достаточный ли у вас уровень тормозной жидкости для безопасной поездки.";
        case 12: return "покажите, как вы будете проверять функционирование указателей поворота.";
        case 13: return "объясните, как проверять работу стоп-сигналов.";
        case 14: return "объясните, как проверить, что рабочий тормоз работает.";
        case 15: return "покажите, как можно убедиться, что все огни автомобиля работают.";
        case 16: return "объясните, где найдете информацию о рекомендованном давлении в шинах.";
        case 17: return "объясните, как вы будете проверять давление воздуха в шинах.";
        case 18: return "объясните как провеять глубину протектора шины.";
        case 19: return "объясните как проверять общее состояние шин для безопасного вождения.";
        case 20: return "покажите, как вы будете провеять функционирование аварийной сигнализации.";

        default: return "error";
    }
};

function QuestionLt(number){
    switch(number){
        case 1: return "Parodykite, kur tikrinsite alyvos lygį variklyje.";
        case 2: return "Paaiškinkite, kaip tikrinsite, ar alyvos lygis variklyje yra pakankamas.";
        case 3: return "Parodykite arba paaiškinkite, kaip tikrinsite, ar veikia vairo stiprintuvas.";
        case 4: return "Parodykite, kur tikrinsite skysčio lygį variklio aušinimo sistemoje.";
        case 5: return "Paaiškinkite, kaip tikrinsite, ar aušinimo skysčio lygis yra pakankamas.";
        case 6: return "Paaiškinkite, kaip tikrinsite, ar veikia stovėjimo stabdys.";
        case 7: return "Parodykite, kur pilsite langų plovimo skystį.";
        case 8: return "Paaiškinkite, kaip tikrinsite langų plovimo skysčio lygį.";
        case 9: return "Parodykite, kaip tikrinsite, ar veikia įspėjamasis garso signalas.";
        case 10: return "Parodykite, kur yra stabdžių skysčio rezervuaras (talpa).";
        case 11: return "Paaiškinkite, kaip tikrinsite, ar stabdžių skysčio lygis yra pakankamas, kad saugiai važiuotumėte.";
        case 12: return "Paaiškinkite, kaip tikrinsite, ar veikia posūkių signalai.";
        case 13: return "Paaiškinkite, kaip patikrinsite, ar veikia stabdžių šviesos signalai.";
        case 14: return "Paaiškinkite, kaip tikrinsite, ar veikia darbinis stabdys.";
        case 15: return "Paaiškinkite, kaip tikrinsite, ar veikia visi žibintai.";
        case 16: return "Paaiškinkite, kur ieškosite informacijos apie rekomenduojamą oro slėgį padangose.";
        case 17: return "Paaiškinkite, kaip tikrinsite oro slėgį padangose.";
        case 18: return "Paaiškinkite, kaip tikrinsite, ar padangų protektoriaus rašto gylio pakanka, kad saugiai važiuotumėte.";
        case 19: return "Paaiškinkite, kaip tikrinsite, ar bendra padangų būklė tinkama saugiai važiuoti.";
        case 20: return "Paaiškinkite, kaip tikrinsite, ar veikia avarinė šviesos signalizacija.";

        default: return "error";
    }
};


function Question(number, lang){
    var numberAsNumber = Number(number);

    if((!lang) 
        || (lang === 'ru')){
        return QuestionRu(numberAsNumber);
    }
    else{
        return QuestionLt(numberAsNumber);
    }
}

export default Question;


