import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image10 from '../../img/10_LiquidBreaks.png';


class Answer11 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <img src={image10} alt="Breaks liquid reservoir"/>

            <ul>
                <li>Убедитесь что машина находится в горизонтальном положении.</li>
                <li>Поднимите крышку капота.</li>                        
                <li>Уровень жидкости должен находиться между отметками MAX и MIN на боковой стенке бачка тормозной жидкости.</li>
                <li>Примечание: будьте осторожны, проверяя уровень при работающем или ещё не остывшем двигателе. </li>                        
            </ul>	
        </div>
    }
}

export default Answer11;