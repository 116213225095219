import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image7 from '../../img/07_Water.jpg';


class Answer08 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <img src={image7} alt="Window washer reservoir"/>
               
            <ul>
                <li>Įsitikinkite, kad mašina yra horizontalioje padėtyje.</li>
                <li>Pakelkite variklio dangtį. </li>
                <li>Rezervuaras paprastai būna permatomas, su MIN ir MAX žymomis, todėl skysčio lygį galima patikrinti vizualiai.</li>
                <li>Kad baigėsi skystis gali rodyti ir prietaisų skydelyje esanti kontrolinė lemputė. </li>                        
            </ul>	
        </div>
    }
}

export default Answer08;