import React from 'react';    
import { Link } from "react-router-dom";


class Page404 extends React.Component
{    
    render(){
        return <div >
            Hi, you're at the 404 page.
            There's nothing interesting here, so just go to <Link to="/">main</Link>
        </div>
    }
}

export default Page404;