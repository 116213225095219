import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer15 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <ul>
                <li> Visi žibintai turi būti tikrinami vizualiai, kai automobilio variklis veikia.</li>
                <li> Įjunkite priekinius žibintus ir apsižvalgykite. </li>
                <li> Transporto priemonės priekyje turi degti artimosios arba tolimosios šviesos (priklausomai nuo situacijos), ir dienos šviesos žibinai. </li>
                <li> Transporto priemonės gale gali degti raudonos tolimosios šviesos, atbulinės eigos žibintas, galiniai rūko žibintai (jei įrengti) ir valstybinio numerio ženklo lemputė. </li>                    
            </ul>	
        </div>
    }
}

export default Answer15;