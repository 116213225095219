import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image1 from '../../img/OpenTheHood.png';


class Answer01 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <img src={image1} alt="Where to open the hood"/>
                 
                 <iframe width="300" height="150" 
                     src="https://www.youtube.com/embed/lOFc-6Xx8io" 
                     title="Открываем капот - 1" 						  
                     allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                     allowfullscreen></iframe>

                 <iframe width="300" height="150" 
                     src="https://www.youtube.com/embed/RfwjFw5HzX8" 
                     title="Как открыть капот автомобиля?" 						  
                     allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                     allowfullscreen></iframe>

                     
                 <ul>
                     <li>Уровень масла в двигателе проверяется под капотом автомобиля.</li>
                     <li>Желая открыть капот нужно потянуть рукоятку которая находится под приборной панелью с левой стороны.</li>
                 </ul>	
        </div>
    }
}

export default Answer01;