import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer14 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <ul>
                <li> Darbinis (pagrindinis) stabdys laikomas techniškai tvarkingu, jei stabdys veikia, kai pedalas nuspaudžiamas su 1\2 jėgos.</li>
                <li> Jei kiekvieną kartą nuspaudus stabdžių pedalą jis nuspaudžiamas iki galo, stabdžiai yra sugedę ir transporto priemone toliau važiuoti negalima.</li>                        
            </ul>	
        </div>
    }
}

export default Answer14;