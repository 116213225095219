import React, { useContext, useState } from 'react';   
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Page404 from '../components/404.jsx';
import QuestionPage from './QuestionPage.jsx';
import Main from './Main.jsx';
import { PageContext } from '../hooks/PageContext.jsx';

// Use context to pass language and question number https://react.dev/reference/react/useContext#passing-data-deeply-into-the-tree

function SiteRouter (){
    
    const pageContextState = useContext(PageContext);
    const [pageState, pageStateUpdate] = useState(pageContextState);
    
    if(pageState.FuncToUpdate !== pageStateUpdate){
        pageStateUpdate({
            Lang: pageState.Lang,
            QuestionNumber: pageState.QuestionNumber,
            FuncToUpdate: pageStateUpdate
        });
    }

    
    var location = window.location.href;    
    var entries = new URLSearchParams(location) ;
    
    let newLang = pageContextState.Lang; 
    let newNumber = pageContextState.QuestionNumber;

    for(const p of entries){                
        if(p[0].endsWith('Lang')){newLang = p[1];}        // TODO move Lang to constant
        if(p[0].endsWith('QuestionNumber')){newNumber = p[1];}        // TODO move QuestionNumber to constant
    }
    
    if(pageState.Lang !== newLang 
        || pageState.QuestionNumber !== newNumber)
    {
        pageStateUpdate({
            Lang: newLang,
            QuestionNumber: newNumber,
            FuncToUpdate: pageStateUpdate
        }); 
    }

    var main = <PageContext.Provider value={pageState}>
        <Main/>
    </PageContext.Provider>;

    var question=<PageContext.Provider value={pageState}>
        <QuestionPage/>       
    </PageContext.Provider>

    var route = 
        <Route index element={newNumber === 0 ? main : question}/>

    return <BrowserRouter>        
        <Routes>
            {route}            
            
            <Route path="/errorPages/404.html" element={<Page404 />} />   

            <Route path="*" element={<Page404 />} />    
        </Routes>
    </BrowserRouter>
    
}

export default SiteRouter;