import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer12 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <ul>
                <li> Чтобы поворотники работали, должен быть включен замок зажигания. </li>
                <li> Переместите рычаг вверх или вниз, чтобы включить указатели поворота. </li>
                <li> Зеленые стрелки на приборной панели показывают, какой сигнал поворота работает.</li>
                <li> Они выключатся сами, когда вы повернете рулевое колесо назад после выполнения маневра.</li>
                <li> Если индикатор продолжает мигать, вручную верните рычаг в исходное положение.</li>                    
                <li> Если индикатор мигает очень быстро или медленно, это может быть перегоревшая лампочка или плохой контакт в цепи.</li>                    
            </ul>	
        </div>
    }
}

export default Answer12;