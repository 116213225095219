import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer03 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           Eksploatuoti tinkamo automobilio vairas sunkiai sukasi, kai automobilis neužvestas, bet lengvai sukasi, kai automobilis užvestas.
        </div>
    }
}

export default Answer03;