import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer13 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <ul>
                <li>Stabdžių lemputė tikrinama vizualiai, kai nuspaudžiamas stabdžių pedalas.</li>
                <li>Prispauskite automobilio galą prie sienos ir pažiūrėkite į atsispindinčią raudoną stabdžių lemputę arba paprašykite, kad kas nors jums padėtų.</li>                        
            </ul>	
        </div>
    }
}

export default Answer13;