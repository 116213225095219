import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image20 from '../../img/20_Alarm.png';

class Answer19 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <img src={image20} alt="Alarm button"/>
                
            <ul>
                <li>Įjungtos įspėjamosios lemputės tikrinamos vizualiai.</li>
                <li>Užsidegus avariniam įspėjamajam žibintui, mirksi visos posūkių rodyklės.</li>                        
                <li>Norėdami įjungti avarinę įspėjamąją lemputę, paspauskite įspėjamąjį mygtuką (raudoną mygtuką su trikampio simboliu).</li>                        
                <li>Šis jungiklis veikia, kai uždegimo jungiklis yra bet kurioje padėtyje. </li>                        
                <li>Norėdami išjungti avarinį įspėjamąjį žibintą, dar kartą paspauskite jungiklį.</li>                        
            </ul>	
        </div>
    }
}

export default Answer19;