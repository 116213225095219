import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image10 from '../../img/10_LiquidBreaks.png';


class Answer11 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <img src={image10} alt="Breaks liquid reservoir"/>
            
            <ul>
                <li>Įsitikinkite, kad mašina yra horizontalioje padėtyje.</li>
                <li>Pakelkite variklio dangtį. </li>                        
                <li>Skysčio lygis turi būti tarp MAX ir MIN žymų, esančių stabdžių skysčio rezervuaro šone.</li>
                <li>Pastaba: būkite atsargūs tikrindami lygį, kai variklis užvestas arba dar nespėjo atvėsti.</li>                        
            </ul>	
        </div>
    }
}

export default Answer11;