import React, { useContext } from 'react';    
import Answer from '../components/Answer';
import { Helmet } from 'react-helmet';
import QuestionWrapper from '../components/QuestionWrapper';
import Header from './Header';
import Footer from './Footer';
import { PageContext } from '../hooks/PageContext';


function QuestionPage ()
{    
    const pageContext = useContext(PageContext);

    let question;
    let answer;

    question = <QuestionWrapper/>
    answer = <Answer/>

    var langMeta = {"lang" : pageContext.Lang};
    var title= '#' + pageContext.QuestionNumber +'  | Практический вопрос Regitra';
    var metaName = 'description';
    var metaContent='Экзамен на вождение в Регитре - практический вопрос ' + pageContext.QuestionNumber;

    var helmet = <Helmet htmlAttributes={langMeta}>
        <title> {title}</title>
        <meta name={metaName} content={metaContent}/>
    </Helmet>
    ;

    return <div>
        {helmet}
        <Header Language={pageContext.Lang}/>
        
        {question}
        {answer}
        <Footer />
    </div>    
}

export default QuestionPage;