import React, { useContext } from 'react';    
import '../css/pageStructure.css';
import LinkToQuestion from '../components/LinkToQuestion';
import { PageContext } from '../hooks/PageContext';


function NavigationPanel()
{   
    const pageContext = useContext(PageContext);

    var numbers1_20 = [ ...Array(20).keys() ].map( i => i+1);
    var links = numbers1_20
        .map(number => <LinkToQuestion key={pageContext.Lang+number} Number={number} Language={pageContext.Lang}/>)
    
    return <div id='navPanel'>    
        {links}            
    </div>

}

export default NavigationPanel;