import Answer01 from "../components/Answers/Answer01.jsx";
import Answer02 from "../components/Answers/Answer02.jsx";
import Answer03 from "../components/Answers/Answer03.jsx";
import Answer04 from "../components/Answers/Answer04.jsx";
import Answer05 from "../components/Answers/Answer05.jsx";
import Answer06 from "../components/Answers/Answer06.jsx";
import Answer07 from "../components/Answers/Answer07.jsx";
import Answer08 from "../components/Answers/Answer08.jsx";
import Answer09 from "../components/Answers/Answer09.jsx";
import Answer10 from "../components/Answers/Answer10.jsx";
import Answer11 from "../components/Answers/Answer11.jsx";
import Answer12 from "../components/Answers/Answer12.jsx";
import Answer13 from "../components/Answers/Answer13.jsx";
import Answer14 from "../components/Answers/Answer14.jsx";
import Answer15 from "../components/Answers/Answer15.jsx";
import Answer16 from "../components/Answers/Answer16.jsx";
import Answer17 from "../components/Answers/Answer17.jsx";
import Answer18 from "../components/Answers/Answer18.jsx";
import Answer19 from "../components/Answers/Answer19.jsx";
import Answer20 from "../components/Answers/Answer20.jsx";

import Answer01Lt from "../components/Answers/Answer01Lt.jsx";
import Answer02Lt from "../components/Answers/Answer02Lt.jsx";
import Answer03Lt from "../components/Answers/Answer03Lt.jsx";
import Answer04Lt from "../components/Answers/Answer04Lt.jsx";
import Answer05Lt from "../components/Answers/Answer05Lt.jsx";
import Answer06Lt from "../components/Answers/Answer06Lt.jsx";
import Answer07Lt from "../components/Answers/Answer07Lt.jsx";
import Answer08Lt from "../components/Answers/Answer08Lt.jsx";
import Answer09Lt from "../components/Answers/Answer09Lt.jsx";
import Answer10Lt from "../components/Answers/Answer10Lt.jsx";
import Answer11Lt from "../components/Answers/Answer11Lt.jsx";
import Answer12Lt from "../components/Answers/Answer12Lt.jsx";
import Answer13Lt from "../components/Answers/Answer13Lt.jsx";
import Answer14Lt from "../components/Answers/Answer14Lt.jsx";
import Answer15Lt from "../components/Answers/Answer15Lt.jsx";
import Answer16Lt from "../components/Answers/Answer16Lt.jsx";
import Answer17Lt from "../components/Answers/Answer17Lt.jsx";
import Answer18Lt from "../components/Answers/Answer18Lt.jsx";
import Answer19Lt from "../components/Answers/Answer19Lt.jsx";
import Answer20Lt from "../components/Answers/Answer20Lt.jsx";

import { RU } from "./Constants.jsx";


function AnswersSelector(number, lang)
{
    var language = !!lang ? lang : RU;
    if(language === RU){
        switch(Number(number)){
            case 1: return <Answer01/>;    
            case 2: return <Answer02/>;     
            case 3: return <Answer03/>;   
            case 4: return <Answer04/>;    
            case 5: return <Answer05/>;    
            case 6: return <Answer06/>;    
            case 7: return <Answer07/>;    
            case 8: return <Answer08/>;    
            case 9: return <Answer09/>;    
            case 10: return <Answer10/>;    
            case 11: return <Answer11/>;    
            case 12: return <Answer12/>;    
            case 13: return <Answer13/>;    
            case 14: return <Answer14/>;    
            case 15: return <Answer15/>;    
            case 16: return <Answer16/>;    
            case 17: return <Answer17/>;    
            case 18: return <Answer18/>;    
            case 19: return <Answer19/>;    
            case 20: return <Answer20/>;    
        }
    }
    else{
        switch(Number(number)){
            case 1: return <Answer01Lt/>;    
            case 2: return <Answer02Lt/>;     
            case 3: return <Answer03Lt/>;   
            case 4: return <Answer04Lt/>;    
            case 5: return <Answer05Lt/>;    
            case 6: return <Answer06Lt/>;    
            case 7: return <Answer07Lt/>;    
            case 8: return <Answer08Lt/>;    
            case 9: return <Answer09Lt/>;    
            case 10: return <Answer10Lt/>;    
            case 11: return <Answer11Lt/>;    
            case 12: return <Answer12Lt/>;    
            case 13: return <Answer13Lt/>;    
            case 14: return <Answer14Lt/>;    
            case 15: return <Answer15Lt/>;    
            case 16: return <Answer16Lt/>;    
            case 17: return <Answer17Lt/>;    
            case 18: return <Answer18Lt/>;    
            case 19: return <Answer19Lt/>;    
            case 20: return <Answer20Lt/>;    
        }
    }

    return <Answer01/>;
};

export default AnswersSelector;