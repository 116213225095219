import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image10 from '../../img/10_LiquidBreaks.png';


class Answer10 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <img src={image10} alt="Breaks liquid reservoir"/>
            
            <ul>
                <li>Stabdžių skysčio lygis tikrinamas po variklio dangčiu.</li>
                <li>Ten yra specialus rezervuaras, paprastai su geltonu dangteliu ir specialiu simboliu ant viršaus.</li>
            </ul>	
        </div>
    }
}

export default Answer10;