import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image18_Wheel from '../../img/18_CheckWheelSafety.png';
import image18_Protector from '../../img/18_ProtectorDepth.png';


class Answer18 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <img src={image18_Wheel} alt="Wheel closely"/>
            <img src={image18_Protector} alt="Turn wheel left/right to see it better"/>
            
            <ul>
                <li>Jei padanga dėvisi tolygiai, protektoriaus nusidėvėjimo indikatorius bus matomas kaip vientisa juosta per visą protektorių. </li>
                <li>Padangos protektoriaus gylį taip pat galima išmatuoti specialiu prietaisu arba paprasčiausiai suvarstykle.</li>
                <li>Vasarą protektoriaus gylis turi būti ne mažesnis kaip 1,6 mm, o žiemą - 3 mm.</li>                        
            </ul>	
        </div>
    }
}

export default Answer18;