import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image20 from '../../img/20_Alarm.png';

class Answer19 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <img src={image20} alt="Alarm button"/>
                
            <ul>
                <li>Световая сигнализация проверяется визуально при включении.</li>
                <li>Аварийный сигнал заставляет мигать все указатели поворота.</li>                        
                <li>Чтобы включить аварийный световой сигнал, нажмите на фланец аварийного сигнала (красная кнопка с символом треугольника). </li>                        
                <li>Этот переключатель работает, когда замок зажигания находится в любом положении. </li>                        
                <li>Чтобы отключить аварийный сигнал, нажмите переключатель еще раз.</li>                        
            </ul>	
        </div>
    }
}

export default Answer19;