import React from 'react';   

class Footer extends React.Component{

    render(){    
        return <div id='footer'>
            <a href='https://youtube.com/playlist?list=PLpxsVm_JOqwrjYGLzHUpnR3Jvvxgvnw4v&feature=shared'> Видео - Youtube </a>
        </div>
    }
}

export default Footer;