import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image18_Wheel from '../../img/18_CheckWheelSafety.png';
import image18_Protector from '../../img/18_ProtectorDepth.png';


class Answer18 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <img src={image18_Wheel} alt="Wheel closely"/>
            <img src={image18_Protector} alt="Turn wheel left/right to see it better"/>
            
            <iframe width="300" height="150" 
                src="https://www.youtube.com/embed/Q0C5EQF5CTc" 
                title="Глубина протектора - 1." 						  
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                allowfullscreen/>

            <iframe width="300" height="150" 
                src="https://www.youtube.com/embed/7hT5y56Ve3g" 
                title="Глубина протектора - 2." 						  
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                allowfullscreen/>

            <iframe width="300" height="150" 
                src="https://www.youtube.com/embed/flDVmTC1GZU" 
                title="Глубина протектора - 3." 						  
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                allowfullscreen/>

            <ul>
                <li>Если шина изношена равномерно, индикатор износа протектора будет выглядеть как сплошная полоса, проходящая через весь протектор.</li>
                <li>Глубину протектора шины можно также измерить с помощью специального прибора или просто штангенциркулем.</li>
                <li>Глубина протектора должна быть не менее 1,6 мм летом и 3 мм зимой.</li>                        
            </ul>	
        </div>
    }
}

export default Answer18;