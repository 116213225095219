import React, { useContext } from 'react';    
import Question from '../data/Questions';
import '../css/pageStructure.css';
import { LT } from '../data/Constants';
import { PageContext } from '../hooks/PageContext';


function QuestionWrapper ()
{    
    var pageContext = useContext(PageContext);
    var question = Question(pageContext.QuestionNumber, pageContext.Lang);
    var starter = pageContext.Lang === LT ? 'Klausimas' : 'Вопрос';

    return <div id='question'>
        <h1>{starter} {pageContext.QuestionNumber}:</h1> {question}
    </div>
}

export default QuestionWrapper;