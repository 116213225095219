import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image04 from '../../img/04_AntiFreeze.png';

class Answer04 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <img src={image04} alt="Antifreeze cap"/>
                 
            <iframe width="300" height="150" 
                src="https://www.youtube.com/embed/-pqQOgNpnI8" 
                title="Проверка уровня охлаждающей жидкости" 						  
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                allowfullscreen/>

            Уровень охлаждающей жидкости в двигателе проверяется под капотом. 
            Там находится специальный пластиковый бачок, обычно с синей крышкой и символом паров сверху.
        </div>
    }
}

export default Answer04;