import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer19 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <ul>
                <li>Все колеса автомобиля должны быть симметрично сбалансированы. </li>
                <li>Проверьте протектор, нет ли на нём неровностей, повреждений, трещин, отверстий или вздутий. Если что-то нашли – шину надо менять.</li>
                <li>Если вы видите ткань или корд, обязательно замените шину на новую. </li>
                <li>На одной оси шины должны быть одинаковой высоты, ширины, износа и сезона.</li>                        
            </ul>		
        </div>
    }
}

export default Answer19;