import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer06 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <ul>
                <li>Стояночный тормоз включается после 3-4 щелчков.</li>
                <li>Если вы чувствуете сопротивление и торможение при включении первой передачи и попытке тронуться с места, значит, стояночный тормоз работает.</li>
            </ul>	
        </div>
    }
}

export default Answer06;