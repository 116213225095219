import React, { useContext } from 'react';    
import '../css/pageStructure.css';
import AnswersSelector from '../data/AnswersSelector';
import { PageContext } from '../hooks/PageContext';
import AdBlock from './AdBlock.jsx';

function Answer() 
{   
    const pageContext = useContext(PageContext);        

    let answer = AnswersSelector(pageContext.QuestionNumber, pageContext.Lang);
    
    return <div id='answer'>
        {answer}            
        <AdBlock key={'adBlock'+Math.floor(Date.now() / 10000)}/>
    </div>
}

export default Answer;