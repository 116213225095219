import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';


class Answer06 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>

           <ul>
                <li>Stovėjimo stabdys įsijungia po 3-4 spragtelėjimų. </li>
                <li>Jei įjungę pirmą pavarą ir bandydami pajudėti jaučiate pasipriešinimą ir stabdymą, stovėjimo stabdys veikia.</li>
            </ul>	
        </div>
    }
}

export default Answer06;