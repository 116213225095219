import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image16 from '../../img/16_WheelsPressure.png';
import image16_2 from '../../img/16_WheelsPressure_2.png';
import image16_3 from '../../img/16_WheelsPressure_2.png';



class Answer16 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <img src={image16} alt="Where to find recommended wheels pressure 1"/>
            <img src={image16_2} alt="Where to find recommended wheels pressure 2"/>
            <img src={image16_3} alt="Where to find recommended wheels pressure 3"/>

            Priklausomai nuo automobilio modelio, rekomenduojamą padangų slėgį rasite:
            
            <ul>
                <li> po degalų bako dangteliu, </li>
                <li> ant vairuotojo pusės stulpelio (tarp priekinių ir galinių durų),</li>
                <li> ant daiktadėžės dangtelio, </li>
                <li> ant variklio dangčio apačios.</li>                    
            </ul>	
        </div>
    }
}

export default Answer16;