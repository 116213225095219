import React, { useContext } from 'react';    
import '../css/pageStructure.css';
import QuestionPageAddress from './QuestionPageAddress';
import { Link } from "react-router-dom";
import { PageContext } from '../hooks/PageContext';

function LinkToQuestion (props)
{   
    const pageContext= useContext(PageContext);

    var newContext = {
        Lang: props.Language,
        QuestionNumber: props.Number,
        FuncToUpdate: pageContext.FuncToUpdate
    };       
    var funcToUpdate = () => pageContext.FuncToUpdate(newContext);      

    var link = QuestionPageAddress(props.Language, props.Number);
    return <Link to={link} onClick={funcToUpdate}> {props.Number} </Link>;    
}

export default LinkToQuestion;