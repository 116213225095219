import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image17 from '../../img/17_wheelsPressure.png';


class Answer17 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
            <img src={image17} alt="How does recommended wheel pressure label look like"/>
                 
                 <ul>
                     <li> Открутите крышку вентиля шины </li>
                     <li> Вставьте манометр </li>
                     <li> Нажмите на него и отпустите на несколько секунд</li>
                     <li> Панель манометра покажет, давление в шине</li>                        
                 </ul>	
        </div>
    }
}

export default Answer17;