import React from 'react';    
import {ACTUAL_ANSWER_DIV_ID} from '../../data/Constants.jsx';
import image7 from '../../img/07_Water.jpg';


class Answer07 extends React.Component
{    
    render(){
        return <div id={ACTUAL_ANSWER_DIV_ID}>
           <img src={image7} alt="Window washer reservoir"/>

            <ul>
                <li>Priekinio stiklo ploviklio rezervuaras yra po variklio dangčiu.</li>
                <li>Bakelio viršuje paprastai būna mėlynas dangtelis su specialiu simboliu.</li>                        
            </ul>	
        </div>
    }
}

export default Answer07;